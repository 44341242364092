import React from 'react';
import { graphql } from 'gatsby';
import Metas from 'components/metas';
import { generateBlocks } from '../components';

export default function Page({ data, location }) {
  const page = data.contentfulPage;
  const banner = page.banner;
  const metaTitle = page.metaTitle || page.title;

  const blocks = generateBlocks({
    blocks: data?.contentfulPage?.contentBlocks,
    location,
    page,
  });

  return (
    <>
      <Metas
        title={`${metaTitle}`}
        description={
          page.metaDescription ? page.metaDescription.metaDescription : null
        }
        shareImage={page.shareImage ? page.shareImage.file.url : null}
        noindex={page.noindex}
        alt=""
        banner={banner}
      />
      {blocks}
    </>
  );
}

export const pageQuery = graphql`
  query PageQuery($id: String) {
    contentfulPage(id: { eq: $id }) {
      ...PageFragment
    }
  }

  fragment PageFragment on ContentfulPage {
    title
    metaTitle
    slug
    metaDescription {
      metaDescription
    }
    shareImage {
      file {
        url
      }
    }
    noindex
    banner {
      id
      announcementText {
        json
      }
      backgroundColor
      show
    }
    contentBlocks {
      ... on Node {
        __typename
        id
        ... on ContentfulHero {
          ...HeroFragment
        }
        ... on ContentfulLogoLine {
          ...LogoLineFragment
        }
        ... on ContentfulCallout {
          ...CalloutFragment
        }
        ... on ContentfulTabbedGallery {
          ...TabbedGalleryFragment
        }
        ... on ContentfulTabbedContent {
          ...TabbedContentFragment
        }

        ... on ContentfulChecklistWithQuotes {
          ...ChecklistWithQuotesFragment
        }
        ... on ContentfulExampleContent {
          ...ExampleContentFragment
        }
        ... on ContentfulTextAndGraphic {
          ...TextAndGraphicFragment
        }
        ... on ContentfulContentSquares {
          ...ContentSquaresFragment
        }
        ... on ContentfulTestimonialCarousel {
          ...TestimonialCarouselFragment
        }
        ... on ContentfulTeamMembers {
          ...TeamMembersFragment
        }
        ... on ContentfulVideoSection {
          ...VideoSectionFragment
        }
        ... on ContentfulPressReleases {
          ...PressReleasesFragment
        }
        ... on ContentfulCta {
          ...CTAFragment
        }
        ... on ContentfulEmbeddedWidget {
          ...EmbeddedWidgetFragment
        }
        ... on ContentfulLongText {
          ...LongTextFragment
        }
        ... on ContentfulPillars {
          ...PillarsFragment
        }
        ... on ContentfulComplexText {
          ...ComplexTextFragment
        }
        ... on ContentfulSlideDeck {
          ...SlideDeckFragment
        }
        ... on ContentfulMultipurposeContainer {
          ...MultipurposeContainerFragment
        }
        ... on ContentfulTabsContainer {
          ...TabsContainerFragment
        }
        ... on ContentfulCodeBlock {
          ...CodeBlockFragment
        }
      }
    }
  }
`;
