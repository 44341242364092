import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from 'images/favicon.ico';
import { useStaticQuery, graphql } from 'gatsby';

export default function Metas({
  title,
  description,
  shareImage,
  noindex = '',
  banner,
}) {
  /*
   *   Query settings
   */
  const settings = useStaticQuery(graphql`
    query LayoutSettingsQuery {
      contentfulSiteSettings(contentful_id: { eq: "6OlY495jBkK7eqij0SOSY8" }) {
        defaultMetaDescription {
          defaultMetaDescription
        }
        defaultShareImage {
          file {
            url
          }
        }
        newselaLogo {
          file {
            url
          }
        }
      }
    }
  `);

  const ldJson = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Newsela',
    url: 'https://www.newsela.com',
    ...(settings?.contentfulSiteSettings?.newselaLogo?.file?.url && {
      logo: settings?.contentfulSiteSettings.newselaLogo.file.url,
    }),
  };

  const metaDescription =
    description ||
    settings?.contentfulSiteSettings?.defaultMetaDescription
      ?.defaultMetaDescription;
  const shareImageUrl =
    shareImage ||
    settings?.contentfulSiteSettings?.defaultShareImage?.file?.url;

  return (
    <Helmet
      title={`${title} `}
      banner={banner}
      meta={[
        { name: 'description', content: metaDescription },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        { name: 'robots', content: noindex ? 'noindex' : 'index' },
        { name: 'theme-color', content: '#0557d5' },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Newsela' },
        { property: 'og:description', content: metaDescription },
        { property: 'og:image', content: shareImageUrl },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@Newsela' },
        { name: 'twitter:image', content: shareImageUrl },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'google-site-verification',
          content: 'XPGlDp7Teb1GThIilMQ2mjVrlZlRaDhiWErquJigV3U',
        },
        {
          name: 'facebook-domain-verification',
          content: 'ixtmqr4blwcrocmvfu06hqn0jywy4n',
        },
      ]}
    >
      <link rel="icon" href={favicon} />
      <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
    </Helmet>
  );
}
